<template>
    <div class="header">
        <div class="search">
            <el-dropdown @command="onCommand" style="margin-left: 20px" trigger="click">
                <div class="banner_con_top_left">
                    <div>全部</div>
                    <img src="../assets/icon／arrow／down@3x (1).png" alt="" class="banner_con_top_left_img" />
                </div>
                <el-dropdown-menu slot="dropdown" style="width: 110px; text-align: center">
                    <el-dropdown-item command="supplySide" style="word-break: keep-all">供给侧 </el-dropdown-item>
                    <el-dropdown-item command="demandSide" style="word-break: keep-all">需求侧 </el-dropdown-item>
                    <el-dropdown-item command="successStories" style="word-break: keep-all">成功案例 </el-dropdown-item>
                    <el-dropdown-item command="information" style="word-break: keep-all">知产咨询动态 </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- <div class="banner_con_top_right" @click="all('/Allsearch')">
                <div class="banner_con_top_right_title">请输入搜索内容</div>
                <div class="banner_con_top_right_con">
                    <img src="../assets/icon／search@3x.png" alt="" class="banner_con_top_right_img" />
                </div>
            </div> -->
            <search-input size="mini" @change="handleChange" v-model="search" placeholder="搜索..." clearable>
                <i slot="append" @click="suffixClick" class="el-icon-search"></i>
            </search-input>
        </div>
        <div v-if="newsList != 0">
            <div class="title">平台动态</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in newsList"
                        :key="index"
                        :to="{ name: 'newsDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="patenstList != 0 || patentList != 0">
            <div class="title">供给侧专利</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in patenstList"
                        :key="index"
                        :to="{ name: 'patentDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                    <router-link
                        v-for="(item, index) in patentList"
                        :key="index"
                        :to="{ name: 'patentDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="logoList != 0">
            <div class="title">供给侧商标</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in logoList"
                        :key="index"
                        :to="{ name: 'logoDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="copyList != 0 || softList != 0">
            <div class="title">供给侧版权/软著</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in copyList"
                        :key="index"
                        :to="{ name: 'copyrightDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                    <router-link
                        v-for="(item, index) in softList"
                        :key="index"
                        :to="{ name: 'copyrightDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="technologyList != 0 || productList != 0">
            <div class="title">供给侧技术/产品</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in technologyList"
                        :key="index"
                        :to="{ name: 'technologyProductDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                    <router-link
                        v-for="(item, index) in productList"
                        :key="index"
                        :to="{ name: 'technologyProductDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="patentsDemandeList != 0 || patentDemandeList != 0">
            <div class="title">需求侧专利</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in patentsDemandeList"
                        :key="index"
                        :to="{ name: 'patentDemandDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                    <router-link
                        v-for="(item, index) in patentDemandeList"
                        :key="index"
                        :to="{ name: 'patentDemandDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="copyDemandeList != 0 || softDemandeList != 0">
            <div class="title">需求侧版权/软著</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in copyDemandeList"
                        :key="index"
                        :to="{ name: 'copyrightDemandDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                    <router-link
                        v-for="(item, index) in softDemandeList"
                        :key="index"
                        :to="{ name: 'copyrightDemandDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="technologyDemandeList != 0 || productDemandeList != 0">
            <div class="title">需求侧技术/产品</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in technologyDemandeList"
                        :key="index"
                        :to="{ name: 'technologyProductDemandDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                    <router-link
                        v-for="(item, index) in productDemandeList"
                        :key="index"
                        :to="{ name: 'technologyProductDemandDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="purchaseList != 0">
            <div class="title">军采信息</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in purchaseList"
                        :key="index"
                        :to="{ name: 'purchaseDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div
            v-if="
                declassificationList != 0 ||
                generalList != 0 ||
                logosList != 0 ||
                copyrightList != 0 ||
                technologysList != 0 ||
                productsList != 0 ||
                softCopyrightList != 0
            "
        >
            <div class="title">成交案例</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in declassificationList"
                        :key="index"
                        :to="{ name: 'successDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                    <router-link
                        v-for="(item, index) in generalList"
                        :key="index"
                        :to="{ name: 'successDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                    <router-link
                        v-for="(item, index) in logosList"
                        :key="index"
                        :to="{ name: 'successDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                    <router-link
                        v-for="(item, index) in copyrightList"
                        :key="index"
                        :to="{ name: 'successDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                    <router-link
                        v-for="(item, index) in softCopyrightList"
                        :key="index"
                        :to="{ name: 'successDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                    <router-link
                        v-for="(item, index) in technologysList"
                        :key="index"
                        :to="{ name: 'successDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                    <router-link
                        v-for="(item, index) in productsList"
                        :key="index"
                        :to="{ name: 'successDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="policyList != 0">
            <div class="title">政策</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in policyList"
                        :key="index"
                        :to="{ name: 'legalDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="lawList != 0">
            <div class="title">法律</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in lawList"
                        :key="index"
                        :to="{ name: 'legalDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="intellectualList != 0">
            <div class="title">知产</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in intellectualList"
                        :key="index"
                        :to="{ name: 'intellectualPropertyDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="techipList != 0">
            <div class="title">技术</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in techipList"
                        :key="index"
                        :to="{ name: 'digitalDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="productipList != 0">
            <div class="title">产品</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in productipList"
                        :key="index"
                        :to="{ name: 'digitalDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="companyipList != 0">
            <div class="title">企业</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in companyipList"
                        :key="index"
                        :to="{ name: 'digitalDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="technicalipList != 0">
            <div class="title">专家</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in technicalipList"
                        :key="index"
                        :to="{ name: 'expertDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="technicalipLists != 0">
            <div class="title">技术经理人</div>
            <div class="content">
                <div class="news-list">
                    <router-link
                        v-for="(item, index) in technicalipLists"
                        :key="index"
                        :to="{ name: 'technicalManagerDetail', query: { id: item.id } }"
                        class="news-link"
                    >
                        <div class="news-con">
                            <div class="text">
                                <div class="flex">
                                    <div class="left"></div>
                                    <span class="text1" v-html="ruleTitle(item.name)"></span>
                                </div>
                                <!-- <img class="text2" src="../assets/icon_more@3x.png" alt="" /> -->
                            </div>
                            <div class="border"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="empty" v-if="empty">
            <i class="el-icon-takeaway-box" style="margin-left: 28px"></i>
            <div class="empty-text" style="font-size: 30px">暂无数据</div>
        </div>
    </div>
</template>
<script>
import SearchInput from '../components/SearchInput';
export default {
    components: {
        SearchInput
    },
    data() {
        return {
            search: '',
            list: [],
            intellectualList: [],
            newsList: [],
            patenstList: [],
            logoList: [],
            patentList: [],
            technologyList: [],
            productList: [],
            softList: [],
            copyDemandeList: [],
            softDemandeList: [],
            technologyDemandeList: [],
            productDemandeList: [],
            copyList: [],
            purchaseList: [],
            declassificationList: [],
            generalList: [],
            logosList: [],
            copyrightList: [],
            softCopyrightList: [],
            technologysList: [],
            productsList: [],
            policyList: [],
            techipList: [],
            lawList: [],
            productipList: [],
            companyipList: [],
            technicalipList: [],
            technicalipLists: [],
            all: [],
            patentsDemandeList: [],
            patentDemandeList: [],
            empty: false
        };
    },
    methods: {
        onCommand(command) {
            if (command == 'supplySide') {
                this.$store.commit('updateShowAdd', true);
                this.$router.push('/patent?caseType=GENERAL');
            } else if (command == 'demandSide') {
                this.$store.commit('updateShowAdd', true);
                this.$router.push('/patentDemand?caseType=GENERAL');
            } else if (command == 'successStories') {
                this.$store.commit('updateShowAdd', true);
                this.$router.push('/successCases?category=GENERAL');
            } else {
                this.$router.push('/newsList?flag=1')
            }
        },
        ruleTitle(item) {
            let titleString = item;
            if (!titleString) {
                return '';
            }
            if (this.search && this.search.length > 0) {
                let replaceReg = new RegExp(this.search, 'g');
                let replaceString = '<span style="color:#FF8700" class="search-text">' + this.search + '</span>';
                titleString = titleString.replace(replaceReg, replaceString);
            }
            return titleString;
        },
        suffixClick() {
            this.handleChange();
        },
        handleChange(name) {
            let search = name;
            this.$http.get('/all/search', { search: search }).then(res => {
                this.all = res;
                if (this.all.length == 0) {
                    this.empty = true;
                } else {
                    this.empty = false;
                }
                // 平台动态
                this.newsList = res.filter(item => {
                    return item.type == 'NEWS';
                });
                // 供给专利
                this.patenstList = res.filter(item => {
                    return item.type == 'DECLASSIFICATION';
                });
                // 供给专利
                this.patentList = res.filter(item => {
                    return item.type == 'GENERAL';
                });
                // 供给商标
                this.logoList = res.filter(item => {
                    return item.type == 'LOGO';
                });
                // 供给版权
                this.copyList = res.filter(item => {
                    return item.type == 'COPY';
                });
                // 供给软著
                this.softList = res.filter(item => {
                    return item.type == 'SOFT';
                });
                // 供给技术
                this.technologyList = res.filter(item => {
                    return item.type == 'TECHNOLOGY';
                });
                // 供给产品
                this.productList = res.filter(item => {
                    return item.type == 'PRODUCT';
                });
                // 需求专利
                this.patentsDemandeList = res.filter(item => {
                    return item.type == 'DECLASSIFICATION_DEMAND';
                });
                // 需求专利
                this.patentDemandeList = res.filter(item => {
                    return item.type == 'GENERAL_DEMAND';
                });
                // 需求版权
                this.copyDemandeList = res.filter(item => {
                    return item.type == 'COPY_DEMAND';
                });
                // 需求软著
                this.softDemandeList = res.filter(item => {
                    return item.type == 'SOFT_DEMAND';
                });
                // 需求技术
                this.technologyDemandeList = res.filter(item => {
                    return item.type == 'TECHNOLOGY_DEMAND';
                });
                // 需求产品
                this.productDemandeList = res.filter(item => {
                    return item.type == 'PRODUCT_DEMAND';
                });
                // 军采信息
                this.purchaseList = res.filter(item => {
                    return item.type == 'PURCHASE';
                });
                // 成交案例专利
                this.declassificationList = res.filter(item => {
                    return item.type == 'DECLASSIFICATION_CASE';
                });
                // 成交案例专利
                this.generalList = res.filter(item => {
                    return item.type == 'GENERAL_CASE';
                });
                // 成交案例商标
                this.logosList = res.filter(item => {
                    return item.type == 'LOGO_CASE';
                });
                // 成交案例版权
                this.copyrightList = res.filter(item => {
                    return item.type == 'COPYRIGHT_CASE';
                });
                // 成交案例软著
                this.softCopyrightList = res.filter(item => {
                    return item.type == 'SOFT_COPYRIGHT_CASE';
                });
                // 成交案例技术
                this.technologysList = res.filter(item => {
                    return item.type == 'TECHNOLOGY_CASE';
                });
                // 成交案例产品
                this.productsList = res.filter(item => {
                    return item.type == 'PRODUCT_CASE';
                });
                // 政策
                this.policyList = res.filter(item => {
                    return item.type == 'POLICY';
                });
                // 法律
                this.lawList = res.filter(item => {
                    return item.type == 'LAW';
                });
                // 技术
                this.techipList = res.filter(item => {
                    return item.type == 'TECHNOLOGY_DIGITAL';
                });
                // 知产
                this.intellectualList = res.filter(item => {
                    return item.type == 'INTELLECTUAL_PROPERTY';
                });
                // 产品
                this.productipList = res.filter(item => {
                    return item.type == 'PRODUCT_DIGITAL';
                });
                // 企业
                this.companyipList = res.filter(item => {
                    return item.type == 'COMPANY_DIGITAL';
                });
                // 专家
                this.technicalipList = res.filter(item => {
                    return item.type == 'EXPERT';
                });
                // 技术经理人
                this.technicalipLists = res.filter(item => {
                    return item.type == 'TECHNICAL_MANAGER';
                });
            });
        }
    }
};
</script>
<style lang="less" scoped>
.header {
    // margin-top: -60px;
    // background: #ffffff !important;
    .empty {
        position: absolute;
        top: 45%;
        left: 45%;
        font-size: 60px;
        color: #ccc;
    }
    /deep/ .el-input {
        width: 644px;
        // margin: 50px 0 50px 0px;
        font-size: 15px;

        .el-input__inner {
            border: 1px solid #ffffff;
            color: #ff8700;
            height: 48px;
            box-sizing: border-box;
            line-height: 48px !important;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .el-input__suffix {
            margin-top: 10px;
        }
    }
    .search {
        display: flex;
        justify-content: center;
        margin: 50px 0 50px 0px;
        font-size: 15px;

        .banner_con_top_left {
            cursor: pointer;
            width: 120px;
            height: 50px;
            background: #ffffff;
            border: 1px solid #009f40;
            margin-right: 1px;
            margin-top: -1px;
            padding: 0px 12px 0px 16px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #727172;
            .banner_con_top_left_img {
                width: 16px;
                height: 16px;
            }
        }
        .banner_con_top_right {
            display: flex;
            height: 48px;
            align-items: center;
            border: 1px solid #009f40;
            background: #ffffff;
            box-sizing: border-box;
            .banner_con_top_right_title {
                width: 450px;
                height: 48px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #c8c9cc;
                padding-left: 20px;
                line-height: 48px;
            }
            .banner_con_top_right_con {
                width: 75px;
                height: 48px;
                background: #009e40;
                padding: 12px 0px 0px 26px;
                box-sizing: border-box;
                .banner_con_top_right_img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
    .el-icon-search {
        height: 46px;
        width: 54px;
        line-height: 46px;
        text-align: center;
        border-radius: 0 0 20px 20px;
    }
    .title {
        width: 980px;
        margin: 0 auto;
        font-size: 14px;
        font-weight: 400;
        color: #00684f;
        line-height: 20px;
        margin-bottom: 10px;
        padding-left: 30px;
    }
    .content {
        width: 980px;
        margin: 0 auto;
        max-height: 290px;
        overflow-x: auto;
        margin-bottom: 50px;
        .news-list {
            background: #ffffff;
            box-shadow: 0px 2px 10px 0px #f5f7fa;
            padding: 20px 20px 0px;
            box-sizing: border-box;
            .news-con {
                .text {
                    .flex();
                    justify-content: space-between;
                    .flex {
                        .flex();
                        .left {
                            width: 4px;
                            height: 4px;
                            background: #e3e4e6;
                            margin-right: 6px;
                        }
                    }
                }
            }
            .border {
                border: 0.1px solid #f2f3f5;
                margin: 10px 0;
                width: 935px;
            }
            .news-link {
                text-decoration: none;
                .flex();
                line-height: 20px;
                .text1 {
                    font-size: 14px;
                    color: #000;
                }
                .text2 {
                    width: 20px;
                    height: 22px;
                }
                &:hover {
                    .text1 {
                        color: @prim;
                    }
                    .text4 {
                        color: @prim;
                    }
                }
            }
        }
    }
}
/deep/ .el-input-group__append {
    background: #009e40;
    border-color: #009e40;
}
.el-dropdown-menu__item:not(.is-disabled):hover {
    color: #01a041;
}
/deep/ .el-icon-search:before {
    font-size: 22px;
}
</style>
